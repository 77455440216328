import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import Grid, { Row } from '@components/Grid';
import Card, { CardImage } from '@components/Card';
import Container from '@components/Container';
import BottomCta from '@components/Home/BottomCta';
import { graphql } from 'gatsby';

import {
  SparaQuery,
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
} from '../../graphql-types';

import SEO from '@components/seo';
import appenPages from '@src/data/appenPages';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import useAppPackages from '@src/hooks/useAppPackages.hook';
import ProductHero from '@src/components/ProductHero';
import SnapScrollContainer from '@src/components/SnapScrollContainer';
import Footer from '@src/components/Footer/Footer';
import * as styles from './spara.module.scss';
import StickyFooter from '@src/components/StickyFooter/StickyFooter';
import AppRatings from '@src/components/Home/AppRatings';
import useIsMobile from '../hooks/useIsMobile.hook';
import DownloadAppButton from '@src/components/DownloadAppButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import CardPackageInfo from '@src/components/Home/CardPackageInfo';

type SparaProps = {
  data: SparaQuery;
};

function Spara({ data }: SparaProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      heroImage,
      pageContent,
    },
  } = data;

  const packageInfo = useAppPackages();

  const heroCard = pageContent[0] as ContentfulCard;
  const uspList = (pageContent[1] as ContentfulTextList).advancedTextListItems;
  const heroSubtitle = `Upp till ${packageInfo.maxInterest}.`;
  const heroImg = useContentfulImage(heroImage);

  const isMobile = useIsMobile();

  return (
    <SnapScrollContainer>
      <Layout theme="white" subNavLinks={appenPages} customFooter={<></>}>
        <SEO title={headLine} description={pageHeaderDescription} />
        <Container>
          <ProductHero
            title={pageHeader}
            subtitle={packageInfo.maxInterest && heroSubtitle}
            image={heroImg}
          >
            <UspList items={uspList as UspListItem[]} />
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
          </ProductHero>
          <Grid spacedRows>
            <Row>
              <Card
                title={heroCard.title}
                text={heroCard.subtitle}
                contentfulImg={useContentfulImage(heroCard.backgroundImage)}
                theme="purple-light"
                ctaElement={
                  <DownloadAppButton buttonText={heroCard.linkText} />
                }
                wide
                snap={true}
              />
              <div className={styles.disclaimer}>
                *Våra sparkonton är i samarbete med Nordiska och omfattas av den statliga insättningsgarantin enligt beslut av Riksgälden. Du som kontohavare har rätt till ersättning för din sammanlagda kontobehållning i Nordiska med högst 1 050 000 kr. Riksgälden betalar ut ersättningen inom 7 arbetsdagar från den dag kreditinstitutet försattes i konkurs eller Finansinspektionen beslutade att garantin ska träda in.
              </div>
            </Row>
          </Grid>
        </Container>
        <div className={styles.bottomContainer}>
          <CardPackageInfo />
          <BottomCta />
          <Footer />
        </div>


        <StickyFooter />
      </Layout>
    </SnapScrollContainer>
  );
}

export default Spara;

export const query = graphql`
  query Spara($locale: String) {
    contentfulPage(pageUrl: { eq: "/spara2.0" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
